import {
  handleQueryResolve
} from '../utils';

export default function (pharmName, ocp) {

    let attrs = [{
      param: 'pharmName',
      type: 'sql.VarChar(50)',
      value: '%' + pharmName + '%' // to support like
    }];
    let where = ['UserName = @pharmName'];

    if (ocp) {
      attrs.push({
        param: 'ocp',
        type: 'sql.VarChar(50)',
        value: '%' + ocp + '%' // to support like
      });

      where.push('PharmID LIKE @ocp');
    }

    return this.query(`
    SELECT 
      PharmacistID as PharmID,
      FirstName+ ' '+LastName AS UserName
    FROM [Pharmacy].[dbo].[Users]
    `, attrs).then(handleQueryResolve);
  }




